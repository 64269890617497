<template>
  <div class="mt-3">
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} users to invite
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <div>
          <button
            class="flex flex-row w-tableIndex items-center focus:outline-none mr-3"
            data-testid="header-0"
          >
            <p class="text-sm-2 text-grey-light">NO.</p>
          </button>
        </div>
        <div class="flex flex-grow w-1/4 -ml-4" data-testid="header-1">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="() => sort('name', 0)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/4" data-testid="header-2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('email', 1)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">EMAIL</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6" data-testid="header-2">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('title', 2)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/6" data-testid="header-3">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">SYSTEM ROLE</p>
          </div>
        </div>
        <div class="flex flex-grow w-1/6 justify-center" data-testid="header-4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('status', 3)"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">STATUS</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="this.mappedList"
          :data-component="itemComponent"
          data-testid="table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import add from "@/assets/img/icons/add.svg";
import email from "@/assets/img/icons/email.svg";

export default {
  name: "CompaniesUsersToInviteTable",
  components: { VirtualList },
  props: {
    usersCount: { type: Number },
    id: { type: Number, default: 1 },
  },
  data: () => ({
    add,
    email,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    sorting: null,
    mappedList: [],
  }),
  computed: {
    ...mapState({
      selectedUsersForInvite: (state) => state.people.selectedUsersForInvite,
    }),
  },
  mounted() {
    this.mappedList = this.selectedUsersForInvite;
  },
  methods: {
    resetRotationMatrix(idx) {
      this.rotationMatrix.map((_, index, array) =>
        index == idx ? null : (array[index] = 1)
      );
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    async sort(key, index) {
      this.mappedList = this.selectedUsersForInvite;
      this.resetRotationMatrix(index);
      switch (key) {
        case "name":
          this.sorting = {name: this.rotationMatrix[0] == -1 ? 1 : 2}
          this.mappedList = this.mappedList.sort((a, b) => this.rotationMatrix[0] * a.fullName.localeCompare(b.fullName))
          break;
        case "email":
          this.sorting = {jobTitle: this.rotationMatrix[1] == -1 ? 1 : 2}
          this.mappedList = this.mappedList.sort((a, b) => this.rotationMatrix[1] * a.email.localeCompare(b.email))
          break;
        case "title":
          this.sorting = {jobTitle: this.rotationMatrix[2] == -1 ? 1 : 2}
          this.mappedList = this.mappedList.sort((a, b) => this.rotationMatrix[2] * a.jobTitle.localeCompare(b.jobTitle))
          break;
        case "status":
          this.sorting = {status: this.rotationMatrix[2] == -1 ? 1 : 2}
          this.mappedList = this.mappedList.sort((a, b) => this.rotationMatrix[2] * (a.status - b.status))
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.new-company-btn {
  line-height: 1;
}
</style>
